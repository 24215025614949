import * as CommonState from './Common';
import Moment from 'moment';
import T from 'i18n-react';
import { history } from '../index';

const initialState = {
    paymentLoading: false,
    authPaymentVerified: false,
    authorizeAccountPaymentLoading: false,
    authPaymentExpired: false,
    paymentErrorMessage: '',
    showAddPaymentMethod: false,
    paymentAccounts: [],
    paymentAccount: {},
    accountPayCreditCardPaymentAccount: {},
    accountPayEcheckPaymentAccount: {},
    accountAutoPayment: {},
    paymentArrangement: {},
    payExtensionRenderData: {
        payExtensionMaxAnnualReq: 0,
        payExtensionMaxDays: 0,
        resourceLangKey: '',
        extendedFormattedDates: {
            extendedDateFormmat1: '',
            extendedDateFormmat2: ''
        }
    },
    showAutoPayForm: false,
    showConfirmModal: false,
    showStopAutoPayConfirmModal: false,
    showDeletePaymentAccountConfirmModal: false,
    showPaymentTypeSelectedModal: false,
    paymentHistoryList: [],
    phYears: [],
    showAccountPaymentSuccessModal: false,
    deletePaymentAccountID: 0,
    redirectToAutoPay: false,
    showPaymentAccountCreated: false,
    i4GoAuthorizeClient: {},
    showCreditCardPaymentType: false,
    showCreditCardAndEcheckPaymentType: false,
    showEcheckPaymentType: false
};

export const actionCreators = {

    setPaymentError: (errorMessage) => (dispatch) => {
        dispatch({ type: 'SET_PAYMENT_ERROR', paymentErrorMessage: errorMessage });
    },

    savePaymentAccount: (pa) => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = CommonState.secureStorage.getItem('privateToken');

        let paymentAccount = {};
        paymentAccount.address = {};
        paymentAccount.accountID = state.common.accountID;
        paymentAccount.active = true;
        paymentAccount.LastUpdateUserName = state.common.account.person.fullName + ' via My Account';

        //CV ZD14560 2020/03/26 adding this condition when is credit card and has stripePK to build the object and send it to the api
        if (pa.paymentAccountTypeID === '3' && pa.paymentStripePK) {
            paymentAccount.paymentAccountTypeID = 3;
            paymentAccount.nameOnAccount = pa.cardNameOnAccount;
            paymentAccount.accountName = pa.cardAccountName;
            paymentAccount.ccv = pa.ccv;
            paymentAccount.address.address1 = pa.address1;
            paymentAccount.address.address2 = pa.address2;
            paymentAccount.address.city = pa.city;
            paymentAccount.address.state = pa.state;
            paymentAccount.address.postalCode = pa.postalCode;
            paymentAccount.billingZipCode = pa.postalCode;
            paymentAccount.paymentToken = pa.paymentToken;

        } else if (pa.paymentAccountTypeID === '3' && !pa.paymentStripePK) { //credit card

            paymentAccount.accountNumber = pa.cardNumberValue.replace(/-/g, '').replace(/_/g, '');
            let cardTypeID = CommonState.getCardType(paymentAccount.accountNumber);

            if (cardTypeID === '4' && pa.ccv.length < 4) {
                dispatch({ type: 'SET_PAYMENT_ERROR', paymentErrorMessage: T.translate('paymentMethod.invalidAmexCcv') });
                return;
            } else if (cardTypeID !== '4' && pa.ccv.length !== 3) {
                dispatch({ type: 'SET_PAYMENT_ERROR', paymentErrorMessage: T.translate('paymentMethod.invalidCcv') });
                return;
            }

            if (!state.common.systemProperties.paymentCardTypes.some(x => x === cardTypeID)) {
                dispatch({ type: 'SET_PAYMENT_ERROR', paymentErrorMessage: T.translate('paymentMethod.invalidCardType') });
                return;
            }

            paymentAccount.cardTypeID = cardTypeID;
            paymentAccount.paymentAccountTypeID = 3;
            paymentAccount.nameOnAccount = pa.cardNameOnAccount;
            paymentAccount.accountName = pa.cardAccountName;
            paymentAccount.ccv = pa.ccv;
            paymentAccount.address.address1 = pa.address1;
            paymentAccount.address.address2 = pa.address2;
            paymentAccount.address.city = pa.city;
            paymentAccount.address.state = pa.state;
            paymentAccount.address.postalCode = pa.postalCode;
            paymentAccount.billingZipCode = pa.postalCode;

            let expiration = pa.expiration.replace(/_/g, '').split('/');

            if (expiration.length === 2) {

                const expireMonth = parseInt(expiration[0]);
                const expireYear = parseInt(expiration[1]);

                if (!isNaN(expireMonth) && !isNaN(expireYear)) {
                    paymentAccount.expireMonth = expireMonth;
                    paymentAccount.expireYear = 2000 + expireYear;
                }
            }

            if (state.common.systemProperties.isSlimCDEnabled && pa.paymentAccountTypeID === '3') {
                var mask = 'XXXXXXXXXXXX';
                paymentAccount.paymentToken = pa.paymentToken;
                if (paymentAccount.accountNumber != undefined && paymentAccount.accountNumber.length > 0)
                    paymentAccount.accountNumber = mask + paymentAccount.accountNumber.substring(paymentAccount.accountNumber.length - 4);
                paymentAccount.ccv = "";
            }


        } else if (pa.paymentAccountTypeID === '4') { //e-check
            paymentAccount.paymentAccountTypeID = 4;
            paymentAccount.accountNumber = pa.bankAccountNumber;
            paymentAccount.routingNumber = pa.bankRoutingNumber.replace(/_/g, '');
            paymentAccount.accountName = pa.bankAccountName;
            paymentAccount.nameOnAccount = pa.bankNameOnAccount;
            paymentAccount.bankAccountType = pa.bankAccountType;

            if (state.common.paymentStripe) {
                paymentAccount.paymentToken = pa.public_token + '|' + pa.account_id;
            }
        }

        let request = {};
        request.paymentAccount = paymentAccount;
        request.accountID = state.common.accountID;


        //if i4go payment enabled and cc and we got access block try to get card unique id
        if (state.common.enableI4GoPayment && state.payment.i4GoAuthorizeClient.i4go_accessblock && pa.paymentAccountTypeID === '3') {

            let requestI4Go = CommonState.getI4GoCardEntryRequest(state.payment.i4GoAuthorizeClient.i4go_accessblock, paymentAccount.cardTypeID,
                paymentAccount.accountNumber, paymentAccount.expireMonth, paymentAccount.expireYear, paymentAccount.ccv);

            var queryString = Object.keys(requestI4Go).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(requestI4Go[key])).join('&');

            fetch(`${state.payment.i4GoAuthorizeClient.i4go_server}/index.cfm?${queryString}`, { method: 'GET' })
                .then(CommonState.handleResponse)
                .then(data => {

                    if (data.i4go_responsecode === '1' && data.i4go_uniqueid) {

                        request.paymentAccount.paymentToken = data.i4go_uniqueid;
                        request.paymentAccount.i4GoCardType = CommonState.getI4GoCardType(paymentAccount.cardTypeID);
                        fetchPaymentAccount(dispatch, getState, request);

                    } else {
                        dispatch({ type: 'SET_PAYMENT_ERROR', errorMessage: data.i4go_responsetext });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    dispatch({ type: 'SET_PAYMENT_ERROR', paymentErrorMessage: error.message });
                });

            dispatch({ type: 'REQUEST_PAYMENT_START' });

        } else {
            fetchPaymentAccount(dispatch, getState, request);
        }
    },

    deletePaymentAccount: () => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = CommonState.secureStorage.getItem('privateToken');

        let paymentAccount = {};
        paymentAccount.LastUpdateUserName = state.common.account.person.fullName + ' via My Account';

        let request = {};
        request.paymentAccountID = state.payment.deletePaymentAccountID;
        request.accountID = state.common.accountID;
        request.paymentAccount = paymentAccount;

        //TODO: review webmethod
        fetch(`${state.common.serviceUrl}/PaymentAccount`, { headers: headers, method: 'DELETE', body: JSON.stringify(request), credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(data => {
                toggleDeletePaymentAccountConfirmModal(dispatch, getState, 0);
                getPaymentAccounts(dispatch, getState);
            })
            .catch(function (error) {
                dispatch({ type: 'SET_PAYMENT_ERROR', paymentErrorMessage: error.message });
            });

        dispatch({ type: 'REQUEST_PAYMENT_START' });

    },

    getI4GoAuthorizeClient: () => (dispatch, getState) => {

        const state = getState();

        if (!state.common.enableI4GoPayment) {
            return;
        }

        fetch(`api/data/I4GoAuthorizeClient`, { credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(data => {

                if (data.i4go_responsecode === 1) {
                    dispatch({ type: 'RESPONSE_I4GO_AUTHORIZE_CLIENT', i4GoAuthorizeClient: data });
                } else {
                    dispatch({ type: 'SET_PAYMENT_ERROR', paymentErrorMessage: data.i4go_responsetext });
                }

            })
            .catch(function (error) {
                dispatch({ type: 'SET_PAYMENT_ERROR', paymentErrorMessage: error.message });
            });

        dispatch({ type: 'REQUEST_PAYMENT_START' });

    },

    getPaymentAccounts: () => (dispatch, getState) => {
        getPaymentAccounts(dispatch, getState);
    },

    getSuggestedPaymentObject: () => (dispatch, getState) => {
        getSuggestedPaymentObject(dispatch, getState);
    },

    getAccountAutoPayment: () => (dispatch, getState) => {
        getAccountAutoPayment(dispatch, getState);
    },

    getPaymentHistory: () => (dispatch, getState) => {
        getPaymentHistory(dispatch, getState);
    },

    getPaymentExtensionData: () => (dispatch, getState) => {
        getPaymentExtensionData(dispatch, getState);
    },

    postPaymentExtension: () => (dispatch, getState) => {
        postPaymentExtension(dispatch, getState);
    },

    toggleAddPaymentMethod: () => (dispatch, getState) => {
        toggleAddPaymentMethod(dispatch, getState);
    },

    toggleShowAutoPayForm: () => (dispatch, getState) => {
        toggleShowAutoPayForm(dispatch, getState);
    },

    toggleStopAutoPayConfirmModal: () => (dispatch, getState) => {
        toggleStopAutoPayConfirmModal(dispatch, getState);
    },

    toggleDeletePaymentAccountConfirmModal: (deletePaymentAccountID) => (dispatch, getState) => {
        toggleDeletePaymentAccountConfirmModal(dispatch, getState, deletePaymentAccountID);
    },

    toggleAccountPaymentSuccessModal: () => (dispatch, getState) => {
        toggleAccountPaymentSuccessModal(dispatch, getState);
    },

    togglePaymentTypeSelectModal: () => (dispatch, getState) => {
        togglePaymentTypeSelectModal(dispatch, getState);
    },

    autoPayInit: () => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = CommonState.secureStorage.getItem('privateToken');

        let fetchPaymentAccounts = fetch(`${state.common.serviceUrl}/PaymentAccounts?AccountID=${state.common.accountID}`, { headers: headers, method: 'GET', credentials: "same-origin" })
            .then(CommonState.handleResponse);

        let fetchAccountAutopayment = fetch(`${state.common.serviceUrl}/AccountAutopayment?AccountID=${state.common.accountID}`, { headers: headers, method: 'GET', credentials: "same-origin" })
            .then(CommonState.handleResponse);

        //call both methods and when got all results process them
        //TODO: review webmethods
        Promise.all([fetchPaymentAccounts, fetchAccountAutopayment])
            .then(([paymentAccounts, accountAutoPayment]) => {

                dispatch({ type: 'SET_PAYMENT_ACCOUNTS', paymentAccounts: paymentAccounts });

                if (paymentAccounts && paymentAccounts.length > 0) {
                    dispatch({ type: 'SET_PAYMENT_ACCOUNT', paymentAccount: paymentAccounts[0] });
                }

                if (accountAutoPayment) {
                    dispatch({ type: 'SET_ACCOUNT_AUTO_PAYMENT', accountAutoPayment: accountAutoPayment });
                } else {
                    dispatch({ type: 'SET_ACCOUNT_AUTO_PAYMENT', accountAutoPayment: {} });
                }

            })
            .catch(function (error) {
                dispatch({ type: 'SET_PAYMENT_ERROR', paymentErrorMessage: error.message });
            });

        dispatch({ type: 'REQUEST_PAYMENT_START' });

    },

    changePaymentAccount: (pa) => (dispatch, getState) => {
        dispatch({ type: 'SET_PAYMENT_ACCOUNT', paymentAccount: pa });
    },

    changeCreditCardPaymentAccount: (pa) => (dispatch, getState) => {
        dispatch({ type: 'SET_ACCOUNT_PAY_PAYMENT_ACCOUNT_CC', accountPayCreditCardPaymentAccount: pa });
    },

    changeEcheckPaymentAccount: (pa) => (dispatch, getState) => {
        dispatch({ type: 'SET_ACCOUNT_PAY_PAYMENT_ACCOUNT_ECHECK', accountPayEcheckPaymentAccount: pa });
    },

    changePaymentAccountByID: (id) => (dispatch, getState) => {

        const state = getState();
        let pa = state.payment.paymentAccounts.filter(x => x.paymentAccountID === id);

        if (pa && pa.length === 1) {
            dispatch({ type: 'SET_PAYMENT_ACCOUNT', paymentAccount: pa[0] });
        }

    },

    saveAutoPayment: (ap) => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = CommonState.secureStorage.getItem('privateToken');

        let toggleForm = false;
        let autoPay = {};

        if (state.payment.accountAutoPayment.accountAutoPayID > 0) {
            autoPay.accountAutoPayID = state.payment.accountAutoPayment.accountAutoPayID;
            toggleForm = true;
        }

        autoPay.accountID = state.common.accountID;
        autoPay.startDate = Moment();
        autoPay.active = true;
        autoPay.paymentAccountID = state.payment.paymentAccount.paymentAccountID;
        autoPay.LastUpdatePersonID = state.common.account.person.personID;
        autoPay.Source = "MyAccount";

        //if postpay account then set default trigger (Bill Due Date) and pay amount (0)
        if (state.common.account.accountBillingTypeID === 2) {

            autoPay.systemEventTriggerID = 1006; //Bill Due Date
            autoPay.autoPayAmount = 0;
            autoPay.balanceThreshold = 0;

        } else { //this is only for prepay

            autoPay.autoPayAmount = 40; //default value

            if (ap.autoPayAmount && !isNaN(parseFloat(ap.autoPayAmount))) {
                autoPay.autoPayAmount = parseFloat(ap.autoPayAmount);
            }

            if (ap.threshold === '1') {

                autoPay.systemEventTriggerID = 261;
                autoPay.balanceThreshold = 40; //default value

                if (ap.balanceThresholdAmount && !isNaN(parseFloat(ap.balanceThresholdAmount))) {
                    autoPay.balanceThreshold = parseFloat(ap.balanceThresholdAmount);
                }

            } else if (ap.threshold === '2') {


                if (ap.daysRemainingOption === '10') {
                    autoPay.systemEventTriggerID = 60;
                } else if (ap.daysRemainingOption === '7') {
                    autoPay.systemEventTriggerID = 70;
                } else if (ap.daysRemainingOption === '5') {
                    autoPay.systemEventTriggerID = 80;
                } else if (ap.daysRemainingOption === '3') {
                    autoPay.systemEventTriggerID = 90;
                } else if (ap.daysRemainingOption === '2') {
                    autoPay.systemEventTriggerID = 100;
                }

            }

        }

        let request = {};
        request.autoPay = autoPay;
        request.accountID = state.common.accountID;
        request.languageID = state.common.langID;

        //TODO: review webmethod
        fetch(`${state.common.serviceUrl}/AutoPayment`, { headers: headers, method: 'POST', body: JSON.stringify(request), credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(data => {

                if (toggleForm) {
                    toggleShowAutoPayForm(dispatch, getState);
                }

                setRedirectToAutoPay(dispatch, false, false);
                getAccountAutoPayment(dispatch, getState);

            })
            .catch(function (error) {
                dispatch({ type: 'SET_PAYMENT_ERROR', paymentErrorMessage: error.message });
            });

        dispatch({ type: 'REQUEST_PAYMENT_START' });

    },

    deleteAutoPayment: () => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = CommonState.secureStorage.getItem('privateToken');

        let autoPay = {};
        autoPay.LastUpdatePersonID = state.common.account.person.personID;
        autoPay.Source = "MyAccount";

        let request = {};
        request.autoPay = autoPay;
        request.accountAutoPayID = state.payment.accountAutoPayment.accountAutoPayID;
        request.accountID = state.common.accountID;

        //TODO: review webmethod
        fetch(`${state.common.serviceUrl}/AutoPayment`, { headers: headers, method: 'DELETE', body: JSON.stringify(request), credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(data => {
                toggleStopAutoPayConfirmModal(dispatch, getState);
                getAccountAutoPayment(dispatch, getState);
            })
            .catch(function (error) {
                dispatch({ type: 'SET_PAYMENT_ERROR', paymentErrorMessage: error.message });
            });

        dispatch({ type: 'REQUEST_PAYMENT_START' });

    },

    postAccountPay: (request) => (dispatch, getState) => {
        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = CommonState.secureStorage.getItem('privateToken');

        request.ipAddress = state.common.ip;
        request.accountID = state.common.accountID;

        if (request.tenderTypeID === 1) {
            request.cardNumber = request.cardNumberValue.replace(/-/g, '');

            if (request.expiration.split('/').length === 2) {
                request.expireMonth = request.expiration.split('/')[0];
                request.expireYear = request.expiration.split('/')[1];
            }
        }

        request.paymentAmount = request.totalPayment;
        if (request.paymentAmount <= 0) {
            dispatch({ type: 'SET_PAYMENT_ERROR', paymentErrorMessage: T.translate('accountPay.paymentAmountGreaterThanZero') });
            return;
        }

        request.customPaymentDistributions = request.accountPaymentDistributions;
        request.accountPaymentDistributions.map(function (paymentDistribution) {
            paymentDistribution.accountID = state.common.accountID;
            paymentDistribution.createdDate = new Date();
        });

        if (!request.anotherPaymentMethod) {

            request.usePaymentProfile = true;

            if (request.tenderTypeID === 1) {
                request.profileIdentifier = state.payment.accountPayCreditCardPaymentAccount.externalProfileIdentifier;
            } else if (request.tenderTypeID === 2) {
                request.profileIdentifier = state.payment.accountPayEcheckPaymentAccount.externalProfileIdentifier;
            }

        } else if (state.common.paymentStripe && request.public_token && request.account_id && !request.paymentStripePK) {
            request.paymentToken = request.public_token + '|' + request.account_id;
        } else if (state.common.systemProperties.isSlimCDEnabled && request.public_token) {
            request.paymentToken = request.public_token;
            request.cardNumber = "";
            request.ccv = "";
        }

        //if i4go payment enabled and cc payment and we got access block try to get unique id
        if (state.common.enableI4GoPayment && state.payment.i4GoAuthorizeClient.i4go_accessblock && request.tenderTypeID === 1 && !request.usePaymentProfile) {

            let cardTypeID = CommonState.getCardType(request.cardNumber);

            let requestI4Go = CommonState.getI4GoCardEntryRequest(state.payment.i4GoAuthorizeClient.i4go_accessblock, cardTypeID,
                request.cardNumber, request.expireMonth, request.expireYear, request.ccv);

            var queryString = Object.keys(requestI4Go).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(requestI4Go[key])).join('&');

            fetch(`${state.payment.i4GoAuthorizeClient.i4go_server}/index.cfm?${queryString}`, { method: 'GET' })
                .then(CommonState.handleResponse)
                .then(data => {

                    if (data.i4go_responsecode === '1' && data.i4go_uniqueid) {

                        //only send required fields
                        let req = {};
                        req.paymentAmount = request.paymentAmount;
                        req.paymentToken = data.i4go_uniqueid;
                        req.ipAddress = request.ipAddress;
                        req.accountID = request.accountID;
                        req.cardZipCode = request.cardZipCode;
                        req.nameOnAccount = request.nameOnAccount;
                        req.tenderTypeID = request.tenderTypeID;
                        req.i4GoCardType = CommonState.getI4GoCardType(cardTypeID);

                        fetchAccountPayment(dispatch, getState, req);

                    } else {
                        dispatch({ type: 'SET_PAYMENT_ERROR', paymentErrorMessage: data.i4go_responsetext });
                    }

                })
                .catch(function (error) {
                    console.log(error);
                    dispatch({ type: 'SET_PAYMENT_ERROR', paymentErrorMessage: error.message });
                });

            dispatch({ type: 'REQUEST_PAYMENT_START' });

        } else {
            fetchAccountPayment(dispatch, getState, request);
        }

    },

    clearPaymentState: () => (dispatch) => {
        dispatch({ type: 'CLEAR_PAYMENT_STATE' });
    },

    goToPaymentMethod: (redirect) => (dispatch) => {
        setRedirectToAutoPay(dispatch, redirect, false);
        history.push('/myaccount/paymentmethods');
    },

    authorizeAccountPayment: (request) => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = CommonState.secureStorage.getItem('privateToken');

        request.ipAddress = state.common.ip;
        request.accountID = state.common.accountID;

        if (request.tenderTypeID === 1) {
            request.cardNumber = request.cardNumberValue.replace(/-/g, '');

            if (request.expiration.split('/').length === 2) {
                request.expireMonth = request.expiration.split('/')[0];
                request.expireYear = request.expiration.split('/')[1];
            }
        }

        request.paymentAmount = request.totalPayment;
        if (request.paymentAmount <= 0) {
            dispatch({ type: 'SET_PAYMENT_ERROR', paymentErrorMessage: T.translate('accountPay.paymentAmountGreaterThanZero') });
            return;
        }

        request.customPaymentDistributions = request.accountPaymentDistributions;
        request.accountPaymentDistributions.map(function (paymentDistribution) {
            paymentDistribution.accountID = state.common.accountID;
            paymentDistribution.createdDate = new Date();
        });

        if (!request.anotherPaymentMethod) {

            request.usePaymentProfile = true;

            if (request.tenderTypeID === 1) {
                request.profileIdentifier = state.payment.accountPayCreditCardPaymentAccount.externalProfileIdentifier;
            } else if (request.tenderTypeID === 2) {
                request.profileIdentifier = state.payment.accountPayEcheckPaymentAccount.externalProfileIdentifier;
            }

        } else if (state.common.paymentStripe && request.public_token && request.account_id && !request.paymentStripePK) {
            request.paymentToken = request.public_token + '|' + request.account_id;
        } else if (state.common.systemProperties.isSlimCDEnabled && request.public_token) {
            request.paymentToken = request.public_token;
            request.cardNumber = "";
            request.ccv = "";
        }

        var requestBody = JSON.stringify(request, (key, value) => {
            if (key === "stripe" || key === "elements" || key === "cardNumberElement" || key === "cardExpiryElement" || key === "cardCvcElement") return null;
            else return value;
        });

        fetch(`${state.common.serviceUrl}/AuthorizeAccountPayment`, { headers: headers, method: 'POST', body: requestBody, credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(data => {

                if (data.isApproved) {
                    dispatch({ type: 'AUTH_PAYMENT_SUCCESS' });
                } else {
                    dispatch({ type: 'SET_AUTH_PAYMENT_ERROR', paymentErrorMessage: T.translate('accountPay.accountInfoNotAuthorized') });
                }
            })
            .catch(function (error) {
                dispatch({ type: 'SET_AUTH_PAYMENT_ERROR', paymentErrorMessage: error.message });
            });

        dispatch({ type: 'REQUEST_AUTH_PAYMENT_START' });
    },

    authorizeAccountPaymentExpired: () => (dispatch) => {
        dispatch({ type: 'AUTH_PAYMENT_EXPIRED' });
    },

    authorizeAccountPaymentByPass: () => (dispatch) => {
        dispatch({ type: 'AUTH_PAYMENT_BYPASS' });
    },

};

var fetchPaymentAccount = function (dispatch, getState, req) {

    const state = getState();
    let headers = CommonState.headers;
    headers.Authorization = CommonState.secureStorage.getItem('privateToken');

    fetch(`${state.common.serviceUrl}/PaymentAccount`, { headers: headers, method: 'POST', body: JSON.stringify(req), credentials: "same-origin" })
        .then(CommonState.handleResponse)
        .then(data => {
            getPaymentAccounts(dispatch, getState, true);
            toggleAddPaymentMethod(dispatch, getState);
        })
        .catch(function (error) {
            dispatch({ type: 'SET_PAYMENT_ERROR', paymentErrorMessage: error.message });
        });

    dispatch({ type: 'REQUEST_PAYMENT_START' });

};

var fetchAccountPayment = function (dispatch, getState, req) {

    const state = getState();
    let headers = CommonState.headers;
    headers.Authorization = CommonState.secureStorage.getItem('privateToken');

    //CV ZD14560 2020/03/26 adding this to exclude the stripe objects from the request body, and prevent the circular reference problem
    var requestBody = JSON.stringify(req, (key, value) => {
        if (key === "stripe" || key === "elements" || key === "cardNumberElement" || key === "cardExpiryElement" || key === "cardCvcElement") return null;
        else return value;
    });


    fetch(`${state.common.serviceUrl}/AccountPayment`, { headers: headers, method: 'POST', body: requestBody, credentials: "same-origin" })
        .then(CommonState.handleResponse)
        .then(data => {

            if (data.isApproved) {
                dispatch({ type: 'SET_ACCOUNT_PAY_SUCCESS', accountPaymentSuccess: true, accountPayConfirmationNumber: data.paymentConfirmationNumber });
                toggleAccountPaymentSuccessModal(dispatch, getState);
            } else {
                dispatch({ type: 'SET_PAYMENT_ERROR', paymentErrorMessage: data.errorMessage });
            }
        })
        .catch(function (error) {
            dispatch({ type: 'SET_PAYMENT_ERROR', paymentErrorMessage: error.message });
        });

    dispatch({ type: 'REQUEST_PAYMENT_START' });

};

var setRedirectToAutoPay = function (dispatch, redirect, acctCreated) {
    dispatch({ type: 'SET_ACCOUNT_REDIRECT_TO_AUTOPAY', redirectToAutoPay: redirect, showPaymentAccountCreated: acctCreated });
};

var getPaymentAccounts = function (dispatch, getState, redirect) {

    const state = getState();
    let headers = CommonState.headers;
    headers.Authorization = CommonState.secureStorage.getItem('privateToken');

    //TODO: review webmethod
    fetch(`${state.common.serviceUrl}/PaymentAccounts?AccountID=${state.common.accountID}`, { headers: headers, method: 'GET', credentials: "same-origin" })
        .then(CommonState.handleResponse)
        .then(data => {
            dispatch({ type: 'SET_PAYMENT_ACCOUNTS', paymentAccounts: data });

            if (data && data.length > 0) {
                dispatch({ type: 'SET_PAYMENT_ACCOUNT', paymentAccount: data[0] });
            }

            setPaymentType(dispatch, data);

            if (redirect && state.payment.redirectToAutoPay) {
                setRedirectToAutoPay(dispatch, false, true);
                history.push('/myaccount/autopay');
            }

        })
        .catch(function (error) {
            dispatch({ type: 'SET_PAYMENT_ERROR', paymentErrorMessage: error.message });
        });

    dispatch({ type: 'REQUEST_PAYMENT_START' });

};

var getSuggestedPaymentObject = function (dispatch, getState) {

    const state = getState();
    let headers = CommonState.headers;
    headers.Authorization = CommonState.secureStorage.getItem('privateToken');
    let suggestedPaymentRequest = fetch(`${state.common.serviceUrl}/SuggestedPayment?AccountID=${state.common.accountID}`, { headers: headers, method: 'GET', credentials: "same-origin" })
        .then(CommonState.handleResponse);
    suggestedPaymentRequest.then(suggestedPaymentObject => {
        dispatch({ type: 'SET_SUGGESTED_PAYMENT_OBJECT', suggestedPaymentObject: suggestedPaymentObject });
    }).catch(function (error) {
        dispatch({ type: 'SET_PAYMENT_ERROR', accountErrorMessage: error.message });
    });

    dispatch({ type: 'REQUEST_PAYMENT_START' });
};

var getAccountAutoPayment = function (dispatch, getState) {

    const state = getState();
    let headers = CommonState.headers;
    headers.Authorization = CommonState.secureStorage.getItem('privateToken');

    //TODO: review webmethod
    fetch(`${state.common.serviceUrl}/AccountAutopayment?AccountID=${state.common.accountID}`, { headers: headers, method: 'GET', credentials: "same-origin" })
        .then(CommonState.handleResponse)
        .then(data => {
            dispatch({ type: 'SET_ACCOUNT_AUTO_PAYMENT', accountAutoPayment: data });
        })
        .catch(function (error) {
            dispatch({ type: 'SET_PAYMENT_ERROR', paymentErrorMessage: error.message });
        });

    dispatch({ type: 'REQUEST_PAYMENT_START' });

};

var getPaymentHistory = function (dispatch, getState) {

    const state = getState();
    let headers = CommonState.headers;
    headers.Authorization = CommonState.secureStorage.getItem('privateToken');

    var from = new Date();
    var to = new Date();

    var paymentHistoryMonthsRange = state.common.paymentHistoryMonthsRange * -1;

    from.setHours(0, 0, 0, 0);
    from = CommonState.addMonthsToDate(from, paymentHistoryMonthsRange);

    var fromString = CommonState.formatDate(from, 'MM/DD/YYYY');
    var toString = CommonState.formatDate(to, 'MM/DD/YYYY');

    var request = { AccountID: state.common.accountID, FromDate: fromString, ToDate: toString };

    var queryString = Object.keys(request).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(request[key])).join('&');

    fetch(`${state.common.serviceUrl}/PaymentHistory?${queryString}`, { headers: headers, method: 'GET', credentials: "same-origin" })
        .then(CommonState.handleResponse)
        .then(data => {
            var rows = data.accountTransactions;
            var years = [];

            rows.map(function (row, i) {
                row.year = CommonState.formatDate(row.transactionDate, 'YYYY');
                if (!years.includes(row.year))
                    years.push(row.year);
            });

            years.sort();
            years.reverse();

            dispatch({ type: 'RESPONSE_PAYMENT_HISTORY', paymentHistoryList: rows, years });
        })
        .catch(function (error) {
            dispatch({ type: 'SET_PAYMENT_ERROR', paymentErrorMessage: error.message });
        });

    dispatch({ type: 'REQUEST_PAYMENT_START' });

};

var getPaymentExtensionData = function (dispatch, getState) {

    const state = getState();
    let headers = CommonState.headers;
    headers.Authorization = CommonState.secureStorage.getItem('privateToken');

    var request = { AccountID: state.common.accountID };

    var queryString = Object.keys(request).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(request[key])).join('&');


    fetch(`${state.common.serviceUrl}/PaymentExtensionData?${queryString}`, { headers: headers, method: 'GET', credentials: "same-origin" })
        .then(CommonState.handleResponse)
        .then(data => {
            var resourceLangKey = '';

            switch (data.errorMessage) {
                case "THERE_IS_NOT_UNPAID_BILL":
                    resourceLangKey = 'paymentExtension.rejectedThereIsNotUnpaidBill';
                    break;
                case "MAX_REQUEST_PER_YEAR_REACHED":
                    resourceLangKey = 'paymentExtension.rejectedMaxRequestPerYearReached';
                    break;
                case "PAYMENT_EXTENSION_ALREADY_EXISTS":
                    resourceLangKey = 'paymentExtension.rejectedPaymentExtensionAlreadyExists';
                    break;
                case "OVERDUE_BILL_EXCEEDS_EXTENSION_MAX_DAYS":
                    resourceLangKey = 'paymentExtension.rejectedOverdueBillExceedsExtesionMaxDays';
                    break;
                default:
                    break;
            }

            var errorMessage = data.errorMessage && resourceLangKey === '' ? data.errorMessage : '';
            var extensionDate = data.paymentArrangement === null ? undefined : data.paymentArrangement.extensionDate;

            dispatch({
                type: 'RESPONSE_PAYMENT_EXTENSION_DATA', paymentArrangement: data.paymentArrangement,
                payExtensionRenderData: {
                    payExtensionMaxAnnualReq: data.payExtensionMaxAnnualReq,
                    payExtensionMaxDays: data.payExtensionMaxDays,
                    disconnectDate: data.disconnectDate,
                    paymentDueDate: data.paymentDueDate,
                    resourceLangKey: resourceLangKey,
                    extendedFormattedDates: {
                        extendedDateFormmat1: CommonState.formatDate(extensionDate, 'MM-DD-YYYY'),
                        extendedDateFormmat2: CommonState.formatDate(extensionDate, 'MM/DD/YY')
                    }
                },
                paymentErrorMessage: errorMessage
            });
        })
        .catch(function (error) {
            dispatch({ type: 'SET_PAYMENT_ERROR', paymentErrorMessage: error.message });
        });

    dispatch({ type: 'REQUEST_PAYMENT_START' });

};

var postPaymentExtension = function (dispatch, getState) {

    const state = getState();
    let headers = CommonState.headers;
    headers.Authorization = CommonState.secureStorage.getItem('privateToken');

    var request = { paymentArrangement: state.payment.paymentArrangement, accountID: state.common.accountID };

    fetch(`${state.common.serviceUrl}/PostPaymentArrangement`, { headers: headers, method: 'POST', body: JSON.stringify(request), credentials: "same-origin" })
        .then(CommonState.handleResponse)
        .then(data => {
            if (data.paymentArrangement) {
                dispatch({ type: 'TOGGLE_PAYMENT_EXTENSION_RESULT_CONFIRM', showConfirmModal: true, paymentLoading: false });
            }
            else {
                dispatch({ type: 'SET_PAYMENT_ERROR', paymentErrorMessage: 'Unexpeted error' });
            }
        })
        .catch(function (error) {
            dispatch({ type: 'SET_PAYMENT_ERROR', paymentErrorMessage: error.message });
        });

    dispatch({ type: 'REQUEST_PAYMENT_START' });
};

var toggleAddPaymentMethod = function (dispatch, getState) {
    const state = getState().payment;
    dispatch({ type: 'TOGGLE_ADD_PAYMENT_METHOD', showAddPaymentMethod: !state.showAddPaymentMethod });
};

var toggleShowAutoPayForm = function (dispatch, getState) {
    const state = getState().payment;
    dispatch({ type: 'TOGGLE_SHOW_AUTO_PAYMENT_FORM', showAutoPayForm: !state.showAutoPayForm });
};

var toggleStopAutoPayConfirmModal = function (dispatch, getState) {
    const state = getState().payment;
    dispatch({ type: 'TOGGLE_STOP_AUTO_PAYMENT_CONFIRM', showStopAutoPayConfirmModal: !state.showStopAutoPayConfirmModal });
};

var toggleAccountPaymentSuccessModal = function (dispatch, getState) {
    const state = getState().payment;
    dispatch({ type: 'TOGGLE_ACCOUNT_PAYMENT_SUCCESS_MODAL', showAccountPaymentSuccessModal: !state.showAccountPaymentSuccessModal });
};

var toggleDeletePaymentAccountConfirmModal = function (dispatch, getState, deletePaymentAccountID) {
    const state = getState().payment;
    dispatch({ type: 'TOGGLE_DELETE_PAYMENT_ACCOUNT_CONFIRM', showDeletePaymentAccountConfirmModal: !state.showDeletePaymentAccountConfirmModal, deletePaymentAccountID });
};

var togglePaymentTypeSelectModal = function (dispatch, getState) {
    const state = getState().payment;
    dispatch({ type: 'TOGGLE_PAYMENT_TYPE_SELECTED_MODAL', showPaymentTypeSelectedModal: !state.showPaymentTypeSelectedModal });
};

var setPaymentType = function (dispatch, paymentAccounts) {
    let paymentsAccountsToCheck;
    if (paymentAccounts && paymentAccounts.length > 0) {
        //get credit card and eCheck payment accounts
        paymentsAccountsToCheck = paymentAccounts.filter((item) => item.paymentAccountTypeID == 3 || item.paymentAccountTypeID == 4);

        //all elements are credit card
        if (paymentsAccountsToCheck.every((item) => item.paymentAccountTypeID == 3)) {
            dispatch({ type: 'PAYMENT_TYPE_CREDIT_CARD', showCreditCardPaymentType: true });
        }
        //all elements are eCheck
        else if (paymentsAccountsToCheck.every((item) => item.paymentAccountTypeID == 4)) {
            dispatch({ type: 'PAYMENT_TYPE_ECHECK', showEcheckPaymentType: true });
        }
        //there are both credit card and eCheck
        else if (paymentsAccountsToCheck.some((item) => item.paymentAccountTypeID == 3)
            && paymentsAccountsToCheck.some((item) => item.paymentAccountTypeID == 4)) {
            dispatch({ type: 'PAYMENT_TYPE_CREDITCARD_AND_ECHECK', showCreditCardAndEcheckPaymentType: true });
        }
        else {
            dispatch({ type: 'PAYMENT_TYPE_CREDIT_CARD', showCreditCardPaymentType: true });
        }
    }
    else {
        dispatch({ type: 'PAYMENT_TYPE_CREDIT_CARD', showCreditCardPaymentType: true });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;


    switch (action.type) {
        case 'REQUEST_PAYMENT_START':
            return { ...state, paymentLoading: true, paymentErrorMessage: '' };
        case 'SET_PAYMENT_ERROR':
            return { ...state, paymentErrorMessage: action.paymentErrorMessage, paymentLoading: false, authPaymentVerified: false };
        case 'SET_PAYMENT_ACCOUNTS':
            return { ...state, paymentLoading: false, paymentAccounts: action.paymentAccounts };
        case 'SET_PAYMENT_ACCOUNT':
            return { ...state, paymentAccount: action.paymentAccount };
        case 'SET_ACCOUNT_PAY_PAYMENT_ACCOUNT_CC':
            return { ...state, accountPayCreditCardPaymentAccount: action.accountPayCreditCardPaymentAccount };
        case 'SET_ACCOUNT_PAY_PAYMENT_ACCOUNT_ECHECK':
            return { ...state, accountPayEcheckPaymentAccount: action.accountPayEcheckPaymentAccount };
        case 'SET_ACCOUNT_AUTO_PAYMENT':
            return { ...state, paymentLoading: false, accountAutoPayment: action.accountAutoPayment };
        case 'SET_PAYMENT_EXTENSION':
            return { ...state, paymentLoading: false, paymentArrangement: action.paymentArrangement };
        case 'RESPONSE_PAYMENT_EXTENSION_DATA':
            return { ...state, paymentLoading: false, paymentArrangement: action.paymentArrangement, payExtensionRenderData: action.payExtensionRenderData, paymentErrorMessage: action.paymentErrorMessage };
        case 'TOGGLE_ADD_PAYMENT_METHOD':
            return { ...state, showAddPaymentMethod: action.showAddPaymentMethod };
        case 'TOGGLE_SHOW_AUTO_PAYMENT_FORM':
            return { ...state, showAutoPayForm: action.showAutoPayForm };
        case 'TOGGLE_STOP_AUTO_PAYMENT_CONFIRM':
            return { ...state, showStopAutoPayConfirmModal: action.showStopAutoPayConfirmModal };
        case 'SET_ACCOUNT_PAY_SUCCESS':
            return { ...state, paymentLoading: false, accountPaymentSuccess: action.accountPaymentSuccess, accountPayConfirmationNumber: action.accountPayConfirmationNumber, authPaymentVerified: false };
        case 'TOGGLE_ACCOUNT_PAYMENT_SUCCESS_MODAL':
            return { ...state, showAccountPaymentSuccessModal: action.showAccountPaymentSuccessModal };
        case 'TOGGLE_DELETE_PAYMENT_ACCOUNT_CONFIRM':
            return { ...state, showDeletePaymentAccountConfirmModal: action.showDeletePaymentAccountConfirmModal, deletePaymentAccountID: action.deletePaymentAccountID };
        case 'TOGGLE_PAYMENT_EXTENSION_RESULT_CONFIRM':
            return { ...state, showConfirmModal: action.showConfirmModal, paymentLoading: action.paymentLoading };
        case 'SET_ACCOUNT_REDIRECT_TO_AUTOPAY':
            return { ...state, redirectToAutoPay: action.redirectToAutoPay, showPaymentAccountCreated: action.showPaymentAccountCreated };
        case 'RESPONSE_PAYMENT_HISTORY':
            return { ...state, paymentLoading: false, paymentHistoryList: action.paymentHistoryList, phYears: action.years };
        case 'RESPONSE_I4GO_AUTHORIZE_CLIENT':
            return { ...state, paymentLoading: false, i4GoAuthorizeClient: action.i4GoAuthorizeClient };
        case 'CLEAR_PAYMENT_STATE':
            return initialState;
        case 'SET_SUGGESTED_PAYMENT_OBJECT':
            return { ...state, accountLoading: false, suggestedPaymentObject: action.suggestedPaymentObject };
        case 'TOGGLE_PAYMENT_TYPE_SELECTED_MODAL':
            return { ...state, showPaymentTypeSelectedModal: action.showPaymentTypeSelectedModal };
        case 'TOGGLE_PAYMENT_TYPE_SELECTED_MODAL':
            return { ...state, showPaymentTypeSelectedModal: action.showPaymentTypeSelectedModal };
        case 'PAYMENT_TYPE_CREDIT_CARD':
            return { ...state, showCreditCardPaymentType: action.showCreditCardPaymentType };
        case 'PAYMENT_TYPE_ECHECK':
            return { ...state, showEcheckPaymentType: action.showEcheckPaymentType };
        case 'PAYMENT_TYPE_CREDITCARD_AND_ECHECK':
            return { ...state, showCreditCardAndEcheckPaymentType: action.showCreditCardAndEcheckPaymentType };
        case 'REQUEST_AUTH_PAYMENT_START':
            return { ...state, authorizeAccountPaymentLoading: true};
        case 'AUTH_PAYMENT_SUCCESS':
            return { ...state, authorizeAccountPaymentLoading: false, authPaymentVerified: true };     
        case 'AUTH_PAYMENT_EXPIRED':
            return { ...state, authorizeAccountPaymentLoading: false, authPaymentExpired: true, authPaymentVerified : false};  
        case 'SET_AUTH_PAYMENT_ERROR':
            return { ...state, authorizeAccountPaymentLoading: false, paymentErrorMessage: action.paymentErrorMessage, authPaymentVerified: false };  
        case 'AUTH_PAYMENT_BYPASS':
            return { ...state, authorizeAccountPaymentLoading: false, authPaymentVerified: true };  
        default:
            return state;
    }

};
