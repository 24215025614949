import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as AccountStore from '../../store/Account';
import * as CommonStore from '../../store/Common';
import * as PaymentStore from '../../store/Payment';
import ExternalLink from '../shared/ExternalLink';
import PaymentSidebar from './PaymentSidebar';
import LoadingOverlay from '../shared/LoadingOverlay';
import LoadingButton from '../shared/LoadingButton';
import MessageError from '../shared/MessageError';
import ConfirmModal from '../shared/ConfirmModal';
import validator from 'validator';
import T from 'i18n-react';
import Select from 'react-select';
import { PaymentAccountOption } from './PaymentAccountOption';
import { PaymentAccountSingleValue } from './PaymentAccountSingleValue';
import ReactPlaid from "react-plaid";
import SuggestedPayment from '../shared/SuggestedPayment';

const Scroll = require('react-scroll');
const card = require(`../../images/${process.env.REACT_APP_CLIENT}/card.png`);
const paymentIcon = require(`../../images/${process.env.REACT_APP_CLIENT}/payment-icon.png`);

const Recaptcha = require('react-recaptcha');

class PayECheck extends Component {

    constructor(props) {
        super(props);

        this.state = {
            anotherPaymentMethod: true,
            tenderTypeID: 2,
            amountOptionID: "2",
            amount: '',
            accountNumber: '',
            routingNumber: '',
            nameOnAccount: '',
            bankAccountType: '0',
            reactPlaidOpen: false,
            account_id: '',
            open: false,
            fields: [
                { name: 'accountNumber', isRequired: true, conditionField: 'anotherPaymentMethod', conditionValue: true, paymentStripeRequired: false },
                { name: 'routingNumber', isRequired: true, conditionField: 'anotherPaymentMethod', conditionValue: true, paymentStripeRequired: false },
                { name: 'account_id', isRequired: true, conditionField: 'anotherPaymentMethod', conditionValue: true, paymentStripeRequired: true },
                { name: 'nameOnAccount', isRequired: true, conditionField: 'anotherPaymentMethod', conditionValue: true, paymentStripeRequired: false, minTextWords: 2 }
            ],
            suggestedPaymentObject: {},
            totalPayment: 0,
            accountPaymentDistributions: [],
            paymentChargeFee: {}
        };
    }

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('accountPay.titleCheck');

        if (this.props.account.accountNumber) {
            this.props.getPaymentAccounts();
            this.props.getSuggestedPaymentObject();

        }
    }

    componentWillReceiveProps(nextProps) {
        //when user refresh the browser - once we get the account - get screen data
        if ((nextProps.account.accountNumber && !this.props.account.accountNumber) || nextProps.account.accountNumber !== this.props.account.accountNumber) {
            this.props.getPaymentAccounts();
            this.props.getSuggestedPaymentObject();

            let state = this.state;
            this.setState({ suggestedPaymentObject: state.suggestedPaymentObject });
        }
        this.setState({ suggestedPaymentObject: nextProps.suggestedPaymentObject });    

        if (nextProps.systemProperties.isLoaded) {
            this.setState({ paymentChargeFee: this.getPaymentChargeFeeObj(nextProps.systemProperties.paymentusECheckCharge) });
        }
    }


    onChange = (e) => {

        let fields = this.state.fields.filter(x => x.name === e.target.name);
        if (fields && fields.length === 1) {
            let field = fields[0];

            if (field.isRequired && field.isRequiredError && e.target.value) {
                if (field.minTextWords) {
                    var words = e.target.value.trim().split(' ');
                    if (words.length >= field.minTextWords) {
                        field.isRequiredError = false;
                    }
                }
                else {
                    field.isRequiredError = false;
                }
            }

            if (field.isNumeric && e.target.value) {
                e.target.value = CommonStore.getNumericInputText(e.target.value);
            }

            if (field.isNumeric && e.target.value) {
                if (validator.isDecimal(e.target.value)) {
                    field.isNumericError = false;
                }
            }
        }

        this.setState({ [e.target.name]: e.target.value });
    }

    isFormValid = () => {

        var state = this.state;
        var valid = true;
        var paymentStripeRequired = this.props.paymentStripe ? true : false;

        if (!state.anotherPaymentMethod && !this.props.accountPayEcheckPaymentAccount) {
            return false;
        }

        this.state.fields.map((field) => {
            if (field.conditionField && field.conditionValue === state[field.conditionField] && (field.paymentStripeRequired === paymentStripeRequired || field.paymentStripeRequired === 'always')) {
                field.value = this.state[field.name];
                field.isRequiredError = false;

                if (field.isRequired) {
                    if (!field.value) {
                        field.isRequiredError = true;
                        valid = false;
                    } else {
                        if (field.minTextWords) {
                            var words = field.value.trim().split(' ');
                            if (words.length < field.minTextWords) {
                                field.isRequiredError = true;
                                valid = false;
                            }
                        }
                    }
                }

                if (field.isNumeric && field.value) {
                    if (!validator.isDecimal(field.value)) {
                        field.isNumericError = true;
                        valid = false;
                    }
                }
            }

            return field;
        });

        this.setState(state);

        if (valid) {
            this.props.setPaymentError('');
        } else {
            this.props.setPaymentError(T.translate("common.thereAreMissingFields"));
        }

        return valid;
    }

    verifyCallback = (response) => {
        if (response) {

            this.setState({ valid_captcha: true });

            if (!this.props.enableAuthenticatePaymentInfo) {
                this.props.authorizeAccountPaymentByPass();
            }
        }
        else {
            this.setState({ valid_captcha: false });
        }
    }

    expiredAuthPaymentRecaptcha = () => {
        this.setState({ valid_captcha: false });
        this.props.authorizeAccountPaymentExpired();
    }

    verifyCardInfo = () => {
        if (this.isFormValid()) {
            this.props.authorizeAccountPayment(this.state);
        }
    }

    pay = () => {
        if (this.isFormValid()) {
            if (this.props.showPaymentAmountWarning && this.props.account.accountBillingTypeID === 2 && this.totalPayment > this.props.account.totalAmountDue) {
                this.setState({ open: true });
            } else {
                this.postAccountPay();
            }
        }
    }

    postAccountPay = () => {
        this.setState({ open: false });
        this.props.postAccountPay(this.state);
    }

    goToDashboard = (e) => {
        this.props.toggleAccountPaymentSuccessModal();
        this.props.setAccount();
        this.props.history.push('/myaccount/dashboard');
    }

    handleChange = (selectedOption) => {
        this.props.changeEcheckPaymentAccount(selectedOption);
        this.setState({ anotherPaymentMethod: false });
    }

    handleClick = () => {
        this.props.changeEcheckPaymentAccount({});
        this.setState({ anotherPaymentMethod: true });
    }

    reactPlaidSuccess = (token, metaData) => {
        this.setState({ account_id: metaData.account_id, public_token: metaData.public_token, reactPlaidOpen: false });
    }

    open = () => {
        this.setState({ open: true });
    }

    close = () => {
        this.setState({ open: false });
    }

    handleTotalAmountCalculation = (totalPaymentAmount, accountPaymentDistributions) => {
        this.setState({ totalPayment: totalPaymentAmount }, function () {
            this.setState({ accountPaymentDistributions: accountPaymentDistributions });
        });
    }

    getPaymentChargeFeeObj = (chargeFeeFromInit) => {
        let paymentChargeFee = {
            paymentMethod: T.translate('suggestedPayment.paymentsIncurseCheck'),
            chargeFee: chargeFeeFromInit
        }

        return paymentChargeFee;
    }

    onTokenizeCreditDetailsClick = (e) => {
        this.setState({ tokenizeCreditDetails: !this.state.tokenizeCreditDetails });
    }

    render() {
        return (
            <div className="payment-section">

                <div className="left-sidebar">
                    <div className="p-main">

                        <LoadingOverlay loading={this.props.paymentLoading} />

                        <div className="page-header">
                            <div className="page-title">{T.translate('accountPay.pageTitleCheck')}</div>
                            <p className="lead text-dark">{T.translate('accountPay.welcomePaymentSystemCheck', this.props.systemProperties)}</p>
                        </div>
                        <div className="diverter" />

                        <h6 className="card-title">{T.translate('accountPay.infoAsOf')} {CommonStore.formatDate(new Date())}</h6>

                        <div className="card bl-blue mb-4 mb-sm-5">
                            <div className="card-body pay-card">
                                <img src={card} alt="card" />
                                {this.props.account.accountBillingTypeID === 1 &&
                                    <ul>
                                        <li>
                                            <span>{T.translate('accountPay.accountNumber')}</span>
                                            <strong>{this.props.account.accountNumber}</strong>
                                        </li>
                                        <li>
                                            <span>{T.translate('accountPay.customerName')}</span>
                                            <strong>{this.props.account.primaryPersonName}</strong>
                                        </li>
                                        <li>
                                            <span>{T.translate('accountPay.amountBelowThreshold')}</span>
                                            <strong>${CommonStore.formatDecimal(this.props.account.disconnectThreshold)}</strong>
                                        </li>
                                        <li>
                                            <span>{T.translate('accountPay.availableBalance')}</span>
                                            <strong className={this.props.account.availableBalance > 0 ? "text-success" : "text-danger"}>${CommonStore.formatDecimal(this.props.account.availableBalance)} {this.props.account.availableBalance > 0 && <span className="text-success font-weight-bold"> {T.translate('dashboard.credit')}</span>}</strong> 
                                        </li>
                                    </ul>
                                }

                                {this.props.account.accountBillingTypeID !== 1 &&
                                    <ul>
                                        <li>
                                            <span>{T.translate('accountPay.accountNumber')}</span>
                                            <strong>{this.props.account.accountNumber}</strong>
                                        </li>
                                        <li>
                                            <span>{T.translate('accountPay.customerName')}</span>
                                            <strong>{this.props.account.primaryPersonName}</strong>
                                        </li>
                                        <li>
                                            <span>{T.translate('accountPay.pastDueCharges')}</span>
                                            <strong>${CommonStore.formatDecimal(this.props.account.pastDueAmount)}</strong>
                                        </li>
                                        <li>
                                            <span>{T.translate('accountPay.totalAccountBalance')}</span>
                                            <strong className={this.props.account.accountBalance < 0 ? "text-danger" : "text-success"}>${CommonStore.formatDecimal(this.props.invertBalance ? Math.abs(this.props.account.accountBalance) : this.props.account.accountBalance)} {this.props.account.accountBalance > 0 && <span className="text-success font-weight-bold"> {T.translate('dashboard.credit')}</span>}</strong> 
                                        </li>
                                    </ul>
                                }

                            </div>
                        </div>

                        <h6 className="card-title mb-4">{T.translate('accountPay.paymentAmount')}</h6>

                        <SuggestedPayment suggestedPaymentObject={this.state.suggestedPaymentObject}
                                          onFinishTotalAmountCalculation={this.handleTotalAmountCalculation}
                                          paymentChargeFee={this.state.paymentChargeFee} />

                        <div className="custom-control custom-checkbox credit-card-checkbox-outer checked">

                            <div className="credit-card-paybox">

                                <div className="row" style={{ marginTop: '20px' }}>

                                    <div className="col-sm-6">
                                        <h6 className="card-title">{T.translate('accountPay.useStoredPaymentMethod')}</h6>
                                    </div>
                                    <div className="col-sm-6 text-left text-sm-right">
                                        <NavLink to="/myaccount/paymentmethods" className="payment-link mb-3 d-inline-block">
                                            <img src={paymentIcon} alt="payment" /><span>{T.translate('autopay.addPaymentMethod')}</span>
                                        </NavLink>
                                        &nbsp;&nbsp;
                                        {this.props.accountPayEcheckPaymentAccount.paymentAccountID && <a href="javascript:;" onClick={this.handleClick} className="payment-link mb-3 d-inline-block"><span>{T.translate('accountPay.clearSelectedOption')}</span></a>}
                                    </div>

                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <Select
                                                value={this.props.accountPayEcheckPaymentAccount}
                                                onChange={this.handleChange}
                                                components={{ Option: PaymentAccountOption, SingleValue: PaymentAccountSingleValue }}
                                                options={this.props.paymentAccounts.filter(x => x.paymentAccountTypeID === 4)}
                                                isSearchable={false}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {!this.props.accountPayEcheckPaymentAccount.paymentAccountID && <div className="">

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <h6 className="card-title">{T.translate('accountPay.orMakeOneTimePayment')}</h6>
                                        </div>
                                    </div>

                                    {!this.props.paymentStripe && <div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="accountNumber">{T.translate('accountPay.bankAccountNumber')}</label>
                                                    <input type="text" id="accountNumber" maxLength={17} name="accountNumber" value={this.state.accountNumber} onChange={this.onChange} className={"form-control " + (this.state.fields[0].isRequiredError ? 'is-invalid' : '')} autoComplete="off" />
                                                    <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="bankAccountType">{T.translate('autopay.bankAccountType')}</label>
                                                    <select className="form-control" id="bankAccountType" name="bankAccountType" value={this.state.bankAccountType} onChange={this.onChange}>
                                                        {
                                                            this.props.systemProperties.bankAccountTypes.map(function (type) {
                                                                return <option key={type.value} value={type.value}>{type.name}</option>;
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="nameOnAccount">{T.translate('autopay.accountHolderName')}</label>
                                                    <input type="text" id="nameOnAccount" name="nameOnAccount" value={this.state.nameOnAccount} onChange={this.onChange} className={"form-control " + (this.state.fields[3].isRequiredError ? 'is-invalid' : '')} autoComplete="off" />
                                                    <div className="invalid-feedback">{T.translate('accountPay.holderNameRequired')}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="routingNumber">{T.translate('accountPay.routingNumber')}</label>
                                                    <input id="routingNumber" name="routingNumber" maxLength={9} value={this.state.routingNumber} onChange={this.onChange} className={"form-control " + (this.state.fields[1].isRequiredError ? 'is-invalid' : '')} autoComplete="off" />
                                                    <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    {this.props.paymentStripe && <div>

                                        <div className="row form-group">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <button className="btn" onClick={() => this.setState({ reactPlaidOpen: true })}>{T.translate('accountPay.clickSelectBankAccount')}</button>
                                                    {this.state.fields[2].isRequiredError && <div className="invalid-feedback" style={{ display: 'block' }}>{T.translate('common.thisFieldIsRequired')}</div>}
                                                </div>
                                            </div>
                                        </div>

                                        <ReactPlaid
                                            clientName={this.props.paymentStripe.clientName}
                                            product={this.props.paymentStripe.product}
                                            apiKey={this.props.paymentStripe.apiKey}
                                            env={this.props.paymentStripe.env}
                                            selectAccount={this.props.paymentStripe.selectAccount}
                                            open={this.state.reactPlaidOpen}
                                            onSuccess={(token, metaData) => this.reactPlaidSuccess(token, metaData)}
                                            onExit={() => this.setState({ reactPlaidOpen: false })}
                                        />
                                    </div>}

                                </div>}

                                <div className="row">
                                    <div className="col-sm-6">
                                        <Recaptcha
                                            sitekey="6LdjG4sUAAAAAJf5HlimijoJ6qPH-HeuvQSSigz6"
                                            verifyCallback={this.verifyCallback}
                                            expiredCallback={this.expiredAuthPaymentRecaptcha}
                                            expireTimeMs={10000}
                                        />
                                    </div>

                                    {this.props.enableAuthenticatePaymentInfo && <div className="col-sm-6">
                                        <LoadingButton
                                            type="button"
                                            className="btn btn-outline-dark m-2"
                                            loading={this.props.authorizeAccountPaymentLoading ? 'true' : 'false'}
                                            disabled={!this.state.valid_captcha || this.props.authPaymentExpired || this.props.authPaymentVerified}
                                            onClick={this.verifyCardInfo}>
                                            Authorize
                                        </LoadingButton>
                                    </div>}

                                </div>

                            </div>

                        </div>

                        <div className="clearfix mb-4" />

                        {!this.props.account.isCashOnly && this.props.authPaymentVerified && !this.props.accountPayEcheckPaymentAccount.paymentAccountID && <div>
                            <div>
                                <p>{T.translate("accountPay.tokenizeCreditDetails")}</p>
                            </div>

                            <div className="checkbox">
                                <label>
                                    <input className="coupon_question sign-up-checkbox mr-1" name="tokenizeCreditDetails" id="tokenizeCreditDetails" value="1" type="checkbox" checked={this.state.tokenizeCreditDetails} onClick={this.onTokenizeCreditDetailsClick} />
                                    {T.translate("accountPay.agreeOnTokenizeCreditDetails")}
                                </label>
                            </div>
                        </div>}

                        {!this.props.account.isCashOnly && this.props.authPaymentVerified && <LoadingButton
                            type="button"
                            className="btn btn-primary mt-0 mb-5"
                            disabled={this.props.paymentLoading || (!this.props.accountPayEcheckPaymentAccount.paymentAccountID && !this.state.tokenizeCreditDetails)}
                            loading={this.props.paymentLoading ? 'true' : 'false'}
                            onClick={this.pay}>
                            {T.translate('accountPay.submitPayment')}
                        </LoadingButton>}

                        <MessageError>{this.props.paymentErrorMessage}</MessageError>

                        {T.translate('accountPay.byClickingPaymentYouAgree') && <p>
                            {T.translate('accountPay.byClickingPaymentYouAgree')} <ExternalLink href={this.props.systemProperties.marketerTosUrl} className="a-link">{T.translate('accountPay.termsOfService')}</ExternalLink>
                            {T.translate('accountPay.andUnderstandThePrivacyStatement')} <NavLink to="/myaccount/plandocuments" className="a-link">{T.translate('accountPay.yourRightsAsCustomer')}</NavLink>
                        </p>}
                    </div>
                </div>

                <ConfirmModal
                    title={T.translate('accountPay.paymentAccepted')}
                    open={this.props.showAccountPaymentSuccessModal}
                    onToggle={this.goToDashboard}
                    loading={this.props.paymentLoading}>
                    <div>
                        <p>{T.translate('accountPay.yourPaymentHasBeenAccepted')}</p>
                        <p className="font-weight-bold">{T.translate('accountPay.yourPaymentConfirmationNumberIs')}</p>
                        <p>{this.props.accountPayConfirmationNumber}</p>
                    </div>
                </ConfirmModal>

                <ConfirmModal
                    title={T.translate('accountPay.paymentWarning')}
                    open={this.state.open}
                    onToggle={this.close}
                    onConfirm={this.postAccountPay}
                    confirmButtonText={T.translate('accountPay.yesSubmitPayment')}>
                    <div><p>{T.translate('accountPay.areYouSureWantPayMoreAmountDue')}</p></div>
                </ConfirmModal>

                <PaymentSidebar />
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.account, ...state.common, ...state.payment }),
    dispatch => bindActionCreators({ ...AccountStore.actionCreators, ...CommonStore.actionCreators, ...PaymentStore.actionCreators }, dispatch)
)(PayECheck);
