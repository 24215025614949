import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as CommonStore from '../../store/Common';
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faDownload } from '@fortawesome/free-solid-svg-icons';
import T from 'i18n-react';
const download = require('../../images/download.png');

class DataGrid extends Component {

    constructor(props) {
        super(props);
        this.state = { currentPage: 1, pagesNumber: 1 };
    }


    handleChildContent = (e) => {
        var row = e.target.parentElement;
        if (this.props.expandibleContent) {
            row.classList.toggle('open');
            if (row.nextElementSibling !== null && row.nextElementSibling.classList.contains('fold')) {
                row.nextElementSibling.classList.toggle('open');
            }
        } else {
            while (row.tagName !== 'TR') {
                row = row.parentElement;
            }

            if (row.classList.contains('accordion-opened')) {
                row.style = "max-height: 60px;";
                row.classList.remove('accordion-opened');
                row.querySelector('.icon-accordion').textContent = '+';
            } else {
                row.style = "max-height: 1000px;";
                row.classList.toggle('accordion-opened');
                row.querySelector('.icon-accordion').textContent = '-';
            }
        }
    }

    backPage = (e) => {
        this.changePage(-1);
    }

    nextPage = (e) => {
        this.changePage(1);
    }

    changePage(upDown) {
        var rowsNumber = this.props.children.rows.length;
        var pageSize = this.props.pageSize === undefined ? rowsNumber : parseInt(this.props.pageSize);
        var newPage = this.state.currentPage + upDown;
        var pagesNumber = Math.ceil(rowsNumber / pageSize);

        if (newPage > 0) {
            if (pagesNumber >= newPage) {
                this.setState({ "currentPage": newPage });
            }
        }
    }

    hrefValue = (row) => {
        var hrefValue = this.props.hrefValue(row);
        if (hrefValue) {
            return hrefValue;
        }

        return '#';
    }

    setCustomValue = (row, column) => {
        return this.props.setCustomValue(row, column);
    }

    render() {
        var allRows = this.props.children.rows;
        if (!allRows)
            allRows = [];

        var rowsNumber = allRows.length;
        var pageSize = this.props.pageSize === undefined ? rowsNumber : parseInt(this.props.pageSize);

        var rowStart = rowsNumber > 0 ? (pageSize * (this.state.currentPage - 1)) + 1 : 0;
        var rowEnd = rowsNumber > 0 ? pageSize * (this.state.currentPage) : 0;

        if (rowStart > rowsNumber)
            rowStart = rowStart - pageSize;

        if (rowEnd > rowsNumber)
            rowEnd = rowsNumber;

        var rows = allRows.slice(rowStart - 1, rowEnd);

        var csvHeaders = this.props.children.columns.map(a => {
            var header = {};
            header.label = a.displayText;
            header.key = a.columnId;
            return header;
        });

        var className = this.props.className !== undefined ? this.props.className : '';

        return (
            <div>
                {(this.props.usePaging === true || this.props.downloadData === true) &&
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            {this.props.usePaging === true &&
                                <div className="table-list-count">
                                    {T.translate('common.paging', { from: rowStart, to: rowEnd, total: rowsNumber })}
                                    <a href="javascript:;" className="count-arrows" onClick={this.backPage}><FontAwesomeIcon icon={faArrowLeft} /></a>
                                    <a href="javascript:;" className="count-arrows" onClick={this.nextPage}> <FontAwesomeIcon icon={faArrowRight} /></a>
                                </div>}
                        </div>
                        <div className="col-sm-6 text-left text-sm-right">
                            {this.props.downloadData === true && rows.length > 0 &&
                            <CSVLink className="btn btn-info pt-1 pb-1 font-weight-normal" data={allRows} filename={this.props.csvFileName} headers={csvHeaders}>
                                    <FontAwesomeIcon className="mr-2" icon={faDownload} />{T.translate('common.downloadData')}
                                </CSVLink>}
                        </div>
                    </div>}
                <div className={this.props.expandibleContent === true ? 'fold-table-box' : 'js-responsive-table mb-5'}>
                    <table className={this.props.expandibleContent === true ? 'fold-table ' + className : className}>
                        <tbody>
                            <tr>
                                {this.props.children.columns.map((column, i) => (
                                    <th key={i} className={column.headerClass} style={column.style}> {column.displayText} </th>
                                ))}
                                {!this.props.expandibleContent && <th />}
                            </tr>
                            {rows.map((row, i) => (
                                <React.Fragment key={i}>
                                    <tr className={this.props.expandibleContent === true ? 'view' : undefined} onClick={this.handleChildContent}>
                                        {this.props.children.columns.map(function (column, j) {
                                            var dataKeyValue = row[this.props.dataKey];
                                            var value = column.dataType !== 'action' ? row[column.columnId] : column.displayText;

                                            if (column.format !== undefined && column.dataType === 'date') {
                                                value = CommonStore.formatDate(row[column.columnId]);
                                            } else if (column.dataType === 'decimal') {
                                                value = CommonStore.formatDecimal(row[column.columnId]);
                                            } else if (column.dataType === 'currency') {
                                                value = '$ ' + CommonStore.formatDecimal(row[column.columnId]);
                                            } else if (column.dataType === 'int') {
                                                value = CommonStore.formatDecimal(row[column.columnId], 0);
                                            } else if (column.dataType === 'download') {
                                                value = value;
                                            }

                                            if (column.isCustomRender) {
                                                value = this.setCustomValue(row, column);
                                            }

                                            var rendered = <React.Fragment />;

                                            if (column.embedHtml && this.props.onEmbedingHtml !== undefined) {
                                                rendered = this.props.onEmbedingHtml(column.columnId, value);
                                            } else {
                                                var spanClass = null;

                                                if (column.hightlightCell && this.props.onHighlightStyle !== undefined) {
                                                    spanClass = this.props.onHighlightStyle(column.columnId, row);
                                                }

                                                rendered = <span className={spanClass !== null ? spanClass : undefined}>{value}</span>;

                                                if (column.bold)
                                                    rendered = <strong>{rendered}</strong>;

                                                if (column.italic)
                                                    rendered = <em>{rendered}</em>;

                                                if (column.image !== undefined)
                                                    rendered = <React.Fragment><img src={column.image} alt={column.displayText} />{rendered}</React.Fragment>;

                                                if (column.dataType === 'action')
                                                    rendered = <a data-id={dataKeyValue} onClick={this.props.onSelectRow} href={this.hrefValue(row)} target="_blank" className="link-btn">{rendered}</a>;

                                                if (column.dataType === 'download')
                                                    rendered = <a href={value} className="link-btn ml-auto text-capitalize" target="_blank"><img src={download} alt={T.translate('common.download')} /><span>{T.translate('common.download')}</span></a>
                                            }

                                            var mobileHeader = !this.props.expandibleContent && j > 0 && <h3>{column.displayText}</h3>;

                                            return <td key={j} className={column.className}>{mobileHeader}{rendered}</td>;
                                        }.bind(this))}

                                        {!this.props.expandibleContent && <td><i className="icon-accordion">+</i></td>}
                                    </tr>
                                    {this.props.expandibleContent === true && <tr className="fold">
                                        {this.props.onExpandableContentBinding !== undefined && this.props.onExpandableContentBinding(row)}
                                    </tr>}
                                </React.Fragment>
                            ))}
                        </tbody>
                        {allRows.length === 0 && <tfoot>
                            <tr>
                                <td colSpan={this.props.children.columns.length}>
                                    {T.translate('common.noRecordsToDisplay')}
                                </td>
                            </tr>
                        </tfoot>}
                    </table>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.common }),
    dispatch => bindActionCreators({ ...CommonStore.actionCreators }, dispatch)
)(DataGrid);