import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import AppNavLink from './AppNavLink';
import * as AccountStore from '../../store/Account';
import * as CommonStore from '../../store/Common';
import * as PaymentStore from '../../store/Payment';
import * as ProfileStore from '../../store/Profile';
import * as UserStore from '../../store/User';
import T from 'i18n-react';
import IdleTimer from 'react-idle-timer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCalendarCheck, faTachometerAlt, faHistory, faPenSquare, faStar, faExchangeAlt, faDollarSign, faCreditCard, faAddressBook, faWarehouse,
    faMoneyBillAlt, faMoneyCheckAlt, faShoppingBag, faUser, faUserAstronaut, faBell, faGlobeAmericas, faEnvelope, faSignOutAlt, faWallet, faUserMinus, faUserPlus
} from '@fortawesome/free-solid-svg-icons';

const logo = require(`../../images/${process.env.REACT_APP_CLIENT}/logo-internal.png`);
const logoRetina = require(`../../images/${process.env.REACT_APP_CLIENT}/logo-internal-retina.png`);
const logoIcon = require(`../../images/${process.env.REACT_APP_CLIENT}/logo-icon.png`);
const menu = require(`../../images/${process.env.REACT_APP_CLIENT}/menu.png`);
const loading = require(`../../images/${process.env.REACT_APP_CLIENT}/loading.gif`);

class LayoutInternal extends Component {

    constructor(props) {
        super(props); 

        this.state = {            
            sidebarActive: false,
            //CV JIRA_1113 2020/09/01 adding this new property to the state, to modify the UI behavior acording to the config value
            isRegulatedClient: this.props.isRegulatedClient()
        };
    }

    componentWillMount() {
        this.props.init();
        this.setState({ isRegulatedClient: this.props.isRegulatedClient() });
    }

    componentWillReceiveProps(nextProps) {
        //when user refresh the browser - once we get the token - get account data
        if (nextProps.initiated && !this.props.initiated && !this.props.account.accountNumber) {
            this.props.init();
            this.setState({ isRegulatedClient: this.props.isRegulatedClient() });
        }
    }

    onIdle = () => {
        this.onLogout();
    }

    onLogout = () => {
        this.props.clearProfileState();
        this.props.clearUserState();
        this.props.clearPaymentState();
        this.props.clearCommonState();
        this.props.clearAccountState(true);
    }

    toggleSidebar = () => {
        this.setState({ sidebarActive: !this.state.sidebarActive });
    }

    closeSidebarIfOpen = () => {
        if (window.innerWidth <= 768 && this.state.sidebarActive) {
            this.setState({ sidebarActive: false });
        }
    }

    toggleLang = () => {
        this.props.toggleLang();
    }

    onChangeAccountServicePoint = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState(state);

        var selectedValueItems = e.target.value.split('|');
        var aspID = parseInt(selectedValueItems[0]);
        var servicePointID = parseInt(selectedValueItems[1]);

        this.props.setAccountServicePoint(aspID, servicePointID);
        this.props.setAccount();
        this.setState({ isRegulatedClient: this.props.isRegulatedClient() });
    }

    onChangeAccount = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState(state);

        this.props.setAccountID(parseInt(e.target.value));
        this.props.setAccount();
        this.setState({ isRegulatedClient: this.props.isRegulatedClient() });
    }

    getExternalRenewalUrl = () => {
        var renewalUrl = this.props.systemProperties.myAcctRenewalUrlRedirection.replace("{accountNumber}", this.props.account.accountNumber);
        return renewalUrl;
    }

    hasExternalRenewalContent = (hasRenewalOffers) => {
        if (hasRenewalOffers && this.props.systemProperties.myAcctRenewalUrlRedirection !== '') {
            return true;
        }
        return false;
    }

    hasInternalRenewalContent = (hasRenewalOffers) => {
        if (hasRenewalOffers && this.props.systemProperties.myAcctRenewalUrlRedirection === '') {
            return true;
        }
        return false;
    }

    accountLegacyVisibility = () => {
        if (this.props.account.legacyAccountNumber === undefined ||
            this.props.account.legacyAccountNumber === '' ||
            this.props.account.hasMultipleServiceAgreements) {
            return true;
        }

        return false;
    }

    showMvo = () => {
        return this.props.systemProperties.myAcctShowMvo;
    }

    showTransferOfService = () => {
        return this.props.systemProperties.myAcctShowTransferOfService;
    }

    showAddLocation = () => {
        return this.props.systemProperties.myAcctShowAddLocation;
    }

    showBackflowInspection = () => {
        if (this.props.systemProperties.myAcctShowBackflowInspection) {

            let spList = this.props.account.locationServices;

            if (spList) {
                //checking if the asset type is backflow device
                return spList.some(x => x.assets && x.assets.some(y => y.assetTypeID == 4007));
            }
        }

        return false;
    }

    getLocationStatusGroup = (camelCase = false) => {
        var sp = this.props.account.servicePoints.find(x => x.accountServicePointID === this.props.accountServicePointID);
        var servicePointStatusID = 0;

        if (sp) {
            servicePointStatusID = sp.servicePointStatusID;
        }

        return CommonStore.getLocationStatusGroup(servicePointStatusID, camelCase);
    }

    render() {
        return (
            <div>

                <IdleTimer
                    element={document}
                    onIdle={this.onIdle}
                    timeout={60000 * 15}
                />

                {this.props.commonLoading && <div className="full-screen-overlay">
                    <img src={loading} alt="loading" />
                </div>}
                <div className="wrapper">
                    <nav className={"sidebar " + (this.state.sidebarActive ? "active" : "")}>
                        <div className="logo">
                            <a href="javascript:;">
                                <img src={logo} className="logo-lg" alt="logo" srcSet={`${logo} 1x, ${logoRetina} 2x`} />
                                <img src={logoIcon} className="logo-sm" alt="logo icon" />
                            </a>
                        </div>
                        <ul className="sidebar-nav-header">
                            <React.Fragment>
                                {this.hasInternalRenewalContent(this.props.account.hasRenewalOffers) && <h5><AppNavLink onClick={this.closeSidebarIfOpen} sidebar={this.state.sidebarActive.toString()} text={T.translate('layout.renewMyService')} to="/myaccount/renewal"><FontAwesomeIcon icon={faStar} /><span className="nav-text">{T.translate('layout.renewMyService')}</span></AppNavLink></h5>}
                                {this.hasExternalRenewalContent(this.props.account.hasRenewalOffers) && <h5><li className="active">{<a href={this.getExternalRenewalUrl()} onClick={this.closeSidebarIfOpen} target="_blank"><FontAwesomeIcon icon={faStar} /><span className="nav-text">{T.translate('layout.renewMyService')}</span></a>}</li></h5>}
                            </React.Fragment>
                        </ul>
                        <h5>{T.translate('layout.account')}</h5>
                        <ul className="sidebar-nav">
                            <AppNavLink onClick={this.closeSidebarIfOpen} sidebar={this.state.sidebarActive.toString()} text={T.translate('layout.accountSummary')} to="/myaccount/dashboard"><FontAwesomeIcon icon={faTachometerAlt} /><span className="nav-text">{T.translate('layout.accountSummary')}</span></AppNavLink>
                            {this.props.account.accountBillingTypeID === 2 && this.accountLegacyVisibility() && <AppNavLink onClick={this.closeSidebarIfOpen} sidebar={this.state.sidebarActive.toString()} text={T.translate('layout.monthlyStatements')} to="/myaccount/monthlystatements"><FontAwesomeIcon icon={faCalendarCheck} /><span className="nav-text">{T.translate('layout.monthlyStatements')}</span></AppNavLink>}
                            {this.props.account.accountBillingTypeID === 1 && <AppNavLink onClick={this.closeSidebarIfOpen} sidebar={this.state.sidebarActive.toString()} text={T.translate('layout.transactionHistory')} to="/myaccount/transactionHistory"><FontAwesomeIcon icon={faHistory} /><span className="nav-text">{T.translate('layout.transactionHistory')}</span></AppNavLink>}
                            {this.state.isRegulatedClient !== true && this.props.systemProperties.myAcctShowPlansDocuments && this.accountLegacyVisibility() && <AppNavLink onClick={this.closeSidebarIfOpen} sidebar={this.state.sidebarActive.toString()} text={T.translate('layout.plansDocuments')} to="/myaccount/plandocuments"><FontAwesomeIcon icon={faPenSquare} /><span className="nav-text">{T.translate('layout.plansDocuments')}</span></AppNavLink>}
                            {this.state.isRegulatedClient === true && <AppNavLink sidebar={this.state.sidebarActive.toString()} text={T.translate('layout.documents')} to="/myaccount/documents"><FontAwesomeIcon icon={faAddressBook} /><span className="nav-text">{T.translate('layout.documents')}</span></AppNavLink>}
                            {this.state.isRegulatedClient === true && <AppNavLink sidebar={this.state.sidebarActive.toString()} text={T.translate('layout.plans')} to="/myaccount/plans"><FontAwesomeIcon icon={faPenSquare} /><span className="nav-text">{T.translate('layout.plans')}</span></AppNavLink>}
                            {this.props.systemProperties.membershipEnabled === true && <AppNavLink sidebar={this.state.sidebarActive.toString()} text={T.translate('layout.memberInfo')} to="/myaccount/memberinfo"><FontAwesomeIcon icon={faUserAstronaut} /><span className="nav-text">{T.translate('layout.memberInfo')}</span></AppNavLink>}                            
                            {this.props.systemProperties.membershipEnabled === true && this.props.systemProperties.displayMemberProgram === true && <AppNavLink sidebar={this.state.sidebarActive.toString()} text={T.translate('layout.memberProgram')} to="/myaccount/memberprogram"><FontAwesomeIcon icon={faStar} /><span className="nav-text">{T.translate('layout.memberProgram')}</span></AppNavLink>}                   
                        </ul>
                        <h5>{T.translate('layout.payments')}</h5>
                        <ul className="sidebar-nav">
                            {!this.props.account.isNoCheck && <AppNavLink onClick={this.closeSidebarIfOpen} sidebar={this.state.sidebarActive.toString()} text={T.translate('layout.setupAutoPay')} to="/myaccount/autopay"><FontAwesomeIcon icon={faDollarSign} /><span className="nav-text">{T.translate('layout.setupAutoPay')}</span></AppNavLink>}
                            {this.props.account.accountBillingTypeID === 2 && this.props.systemProperties.myAcctPaymentTenderTypes.eCheck && !this.props.account.isCashOnly && (!this.props.systemProperties.myAcctHideEcheckForDisconnected || this.props.account.meterStatusID !== 3) && !this.props.account.isNoCheck && <AppNavLink onClick={this.closeSidebarIfOpen} sidebar={this.state.sidebarActive.toString()} text={T.translate('layout.payByEcheck')} to="/myaccount/payecheck"><FontAwesomeIcon icon={faAddressBook} /><span className="nav-text">{T.translate('layout.payByEcheck')}</span></AppNavLink>}
                            {this.props.account.accountBillingTypeID === 1 && this.props.systemProperties.myAcctPaymentTenderTypes.eCheckPrepaid && !this.props.account.isCashOnly && (!this.props.systemProperties.myAcctHideEcheckForDisconnected || this.props.account.meterStatusID !== 3) && !this.props.account.isNoCheck && <AppNavLink onClick={this.closeSidebarIfOpen} sidebar={this.state.sidebarActive.toString()} text={T.translate('layout.payByEcheck')} to="/myaccount/payecheck"><FontAwesomeIcon icon={faAddressBook} /><span className="nav-text">{T.translate('layout.payByEcheck')}</span></AppNavLink>}
                            {this.props.account.accountBillingTypeID === 2 && this.props.systemProperties.myAcctPaymentTenderTypes.creditCard && !this.props.account.isCashOnly && <AppNavLink onClick={this.closeSidebarIfOpen} sidebar={this.state.sidebarActive.toString()} text={T.translate('layout.payByCreditCard')} to="/myaccount/paycreditcard"><FontAwesomeIcon icon={faCreditCard} /><span className="nav-text">{T.translate('layout.payByCreditCard')}</span></AppNavLink>}
                            {this.props.account.accountBillingTypeID === 1 && this.props.systemProperties.myAcctPaymentTenderTypes.creditCardPrepaid && !this.props.account.isCashOnly && <AppNavLink onClick={this.closeSidebarIfOpen} sidebar={this.state.sidebarActive.toString()} text={T.translate('layout.payByCreditCard')} to="/myaccount/paycreditcard"><FontAwesomeIcon icon={faCreditCard} /><span className="nav-text">{T.translate('layout.payByCreditCard')}</span></AppNavLink>}
                            {this.props.account.accountBillingTypeID === 2 && this.props.systemProperties.myAcctPaymentTenderTypes.crypto && !this.props.account.isCashOnly && <AppNavLink onClick={this.closeSidebarIfOpen} sidebar={this.state.sidebarActive.toString()} text={T.translate('layout.payWithCrypto')} to="/myaccount/paycrypto"><FontAwesomeIcon icon={faWallet} /><span className="nav-text">{T.translate('layout.payWithCrypto')}</span></AppNavLink>}
                            {this.props.account.accountBillingTypeID === 1 && this.props.systemProperties.myAcctPaymentTenderTypes.cryptoPrepaid && !this.props.account.isCashOnly && <AppNavLink onClick={this.closeSidebarIfOpen} sidebar={this.state.sidebarActive.toString()} text={T.translate('layout.payWithCrypto')} to="/myaccount/paycrypto"><FontAwesomeIcon icon={faWallet} /><span className="nav-text">{T.translate('layout.payWithCrypto')}</span></AppNavLink>}
                            <AppNavLink onClick={this.closeSidebarIfOpen} sidebar={this.state.sidebarActive.toString()} text={T.translate('layout.myPaymentMethods')} to="/myaccount/paymentmethods"><FontAwesomeIcon icon={faMoneyBillAlt} /><span className="nav-text">{T.translate('layout.myPaymentMethods')}</span></AppNavLink>
                            {this.props.account.accountBillingTypeID === 2 && this.props.systemProperties.myAcctShowPayextension && <AppNavLink onClick={this.closeSidebarIfOpen} sidebar={this.state.sidebarActive.toString()} text={T.translate('layout.paymentExtensions')} to="/myaccount/paymentextension"><FontAwesomeIcon icon={faMoneyCheckAlt} /><span className="nav-text">{T.translate('layout.paymentExtensions')}</span></AppNavLink>}
                            {false && <AppNavLink onClick={this.closeSidebarIfOpen} sidebar={this.state.sidebarActive.toString()} text={T.translate('layout.setupPaymentPlan')} to="/myaccount/paymentplan"><FontAwesomeIcon icon={faShoppingBag} /><span className="nav-text">{T.translate('layout.setupPaymentPlan')}</span></AppNavLink>}
                            <AppNavLink onClick={this.closeSidebarIfOpen} sidebar={this.state.sidebarActive.toString()} text={T.translate('layout.paymentHistory')} to="/myaccount/paymenthistory"><FontAwesomeIcon icon={faHistory} /><span className="nav-text">{T.translate('layout.paymentHistory')}</span></AppNavLink>
                        </ul>
                        {(this.showMvo() || this.showTransferOfService() || this.showAddLocation() || this.showBackflowInspection()) &&
                            <React.Fragment>
                                <h5>{T.translate('layout.serviceRequest')}</h5>
                                <ul className="sidebar-nav">
                                    {this.showMvo() && <AppNavLink onClick={this.closeSidebarIfOpen} sidebar={this.state.sidebarActive.toString()} text={T.translate('layout.moveOut')} to="/myaccount/moveout"><FontAwesomeIcon icon={faUserMinus} /><span className="nav-text">{T.translate('layout.moveOut')}</span></AppNavLink>}
                                    {this.showTransferOfService() && <AppNavLink onClick={this.closeSidebarIfOpen} sidebar={this.state.sidebarActive.toString()} text={T.translate('layout.addLocation')} to="/myaccount/addlocation"><FontAwesomeIcon icon={faUserPlus} /><span className="nav-text">{T.translate('layout.addLocation')}</span></AppNavLink>}
                                    {this.showAddLocation() && <AppNavLink onClick={this.closeSidebarIfOpen} sidebar={this.state.sidebarActive.toString()} text={T.translate('layout.transferService')} to="/myaccount/transferservice"><FontAwesomeIcon icon={faExchangeAlt} /><span className="nav-text">{T.translate('layout.transferService')}</span></AppNavLink>}
                                    {this.showBackflowInspection() && <AppNavLink onClick={this.closeSidebarIfOpen} sidebar={this.state.sidebarActive.toString()} text={T.translate('layout.backflowInspection')} to="/myaccount/backflowinspection"><FontAwesomeIcon icon={faWarehouse} /><span className="nav-text">{T.translate('layout.backflowInspection')}</span></AppNavLink>}
                                </ul>
                            </React.Fragment>
                        }
                        <h5>{T.translate('layout.profile')}</h5>
                        <ul className="sidebar-nav">
                            <AppNavLink onClick={this.closeSidebarIfOpen} sidebar={this.state.sidebarActive.toString()} text={T.translate('layout.editMyProfile')} to="/myaccount/customerprofile"><FontAwesomeIcon icon={faUser} /><span className="nav-text">{T.translate('layout.editMyProfile')}</span></AppNavLink>
                            <AppNavLink onClick={this.closeSidebarIfOpen} sidebar={this.state.sidebarActive.toString()} text={T.translate('layout.manageNotifications')} to="/myaccount/notifications"><FontAwesomeIcon icon={faBell} /><span className="nav-text">{T.translate('layout.manageNotifications')}</span></AppNavLink>
                        </ul>
                        <div className="sidebar-bottom">
                            <h5 className="font-weight-normal">{T.translate('layout.needCustomerService')}</h5>
                            <div className="pl-4 pr-4 pb-4">
                                <p>
                                    {this.props.systemProperties.customerServiceEmail}<br />
                                    {this.props.systemProperties.customerServicePhone}<br />
                                    {this.props.showMarketerMailingAddress && CommonStore.textWrap(this.props.systemProperties.marketerMailingAddress)}
                                </p>
                            </div>
                        </div>
                    </nav>
                    <div className="content">

                        <header className="top-header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-7 align-self-center">
                                        <div className="row">
                                            <div className="col-sm-2 col-3 align-self-center text-center">
                                                <button type="button" id="sidebarCollapse" className="btn-menu" onClick={this.toggleSidebar}><img src={menu} alt="menu icon" /></button>
                                            </div>
                                            <div className="col-sm-10 col-9 align-self-center">
                                                <ul className="header-form">
                                                    <li>
                                                        <select className="form-control" name="accountID" id="accountID" value={this.state.accountID} onChange={this.onChangeAccount}>
                                                            {this.props.accounts && this.props.accounts.map((acct, i) =>
                                                                <option key={acct.accountID} value={acct.accountID}>{T.translate('layout.account')} #{acct.accountNumber}</option>
                                                            )}
                                                        </select>
                                                    </li>
                                                    <li>
                                                        <select className="form-control" name="accountServicePointID" id="accountServicePointID" value={this.props.accountServicePointID + '|' + this.props.servicePointID} onChange={this.onChangeAccountServicePoint}>
                                                            {this.props.account && this.props.account.servicePoints && this.props.account.servicePoints.map((sp, i) =>
                                                                <option key={sp.accountServicePointID} value={sp.accountServicePointID + '|' + sp.servicePointID}>{sp.fullAddress}</option>
                                                            )}
                                                        </select>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5 align-self-center">
                                        <div className="logo-mobile"><img src={logo} alt="logo" /></div>
                                        <ul className="header-nav clearfix">
                                            <li>
                                                <span className={"location-status-label location-" + this.getLocationStatusGroup() + " font-weight-normal rounded text-capitalize w-5 p-2 h-50 d-inline-block"}>{T.translate('layout.' + this.getLocationStatusGroup(true) + 'Status')}</span>
                                            </li>
                                            <li>
                                                <div className="dropdown">
                                                    <a href="javascript:;" onClick={this.toggleLang}>
                                                        <FontAwesomeIcon icon={faGlobeAmericas} />
                                                        <span className="header-nav-text">{this.props.lang === 'en' ? 'ESP' : 'ENG'}</span>
                                                    </a>
                                                    <div className="dropdown-menu animation slideDownIn">
                                                        <a className="dropdown-item" href="#">ENGLISH</a>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <NavLink to={'/myaccount/contactus'}>
                                                    <FontAwesomeIcon icon={faEnvelope} />
                                                    <span className="header-nav-text">{T.translate('layout.contactUs')}</span>
                                                </NavLink>
                                            </li>
                                            <li>
                                                <a onClick={this.onLogout} href="javascript:;">
                                                    <FontAwesomeIcon icon={faSignOutAlt} />
                                                    <span className="header-nav-text">{T.translate('layout.signOut')}</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </header>
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.account, ...state.common }),
    dispatch => bindActionCreators({ ...AccountStore.actionCreators, ...CommonStore.actionCreators, ...PaymentStore.actionCreators, ...ProfileStore.actionCreators, ...UserStore.actionCreators }, dispatch)
)(LayoutInternal);
